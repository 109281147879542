import React, { FC, useState, useEffect } from "react";
import "./pain-overview.scss";

import ShareReportErrorBoundary from "containers/ShareReportErrorBoundary";
import { changeSearchParams } from "utils/changeSearchParams";
import { STATE } from "enums";
import { getPatientPainScore } from "./service";
import { dateStringToDateWithMMDDYYYYFormat } from "utils/dateUtils";
// @ts-ignore
import arrowInsideMobile from "assets/iconImages/arrow_insert_mobile.svg";
import useClientListener from "hooks/useClientListener";

// TODO: Can we use PainScoreApi while setting data instead of any.
const PainOverview: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [painScoreDate, setPainScoreDate] = useState<any>();
  const [hasSubmittedSurvey, setHasSubmittedSurvey] = useState(false);
  const [errorLogs, setErrorLogs] = useState<string[]>([]);
  const [hasError, setHasError] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const clientListener = useClientListener();

  useEffect(() => {
    (async function () {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        const userProcedureId = searchParams.get("UserProcedureId");
        if (!userProcedureId) {
          throw new Error("Invalid User Procedure");
        }
        setIsLoading(true);
        setErrorLogs((prevArray) => [...prevArray, " Calling API;"]);
        const { data } = await getPatientPainScore(userProcedureId);
        setErrorLogs((prevArray) => [...prevArray, " API call succeeded;"]);
        setPainScoreDate(data);
        setHasSubmittedSurvey(isDefined(data.pain_score) && isDefined(data.pain_score_indicator));
      } catch (e: any) {
        setErrorLogs((prevArray) => [...prevArray, e.toString()]);
        setHasError(true);
        console.error(JSON.stringify(e));
        // clientListener.onError();
        throw e;
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  function isDefined<T>(value: T | null | undefined): boolean {
    return value !== null && value !== undefined;
  }

  const seeMoreClicked = () => {
    const userProcedureId = searchParams.get("UserProcedureId") || "0";
    clientListener.openInFullWebContainer(
      `summary/quickview?UserProcedureId=${userProcedureId}`
    );
  };

  const getIndicatorImage = () => {
    switch (painScoreDate?.pain_score_indicator) {
      case "no_change":
        return "/images/painScoreNochange.png";
      case "improved":
        return "/images/painScoreImproved.png";
      case "worsened":
        return "/images/painScoreWorsened.png";
      default:
        return "";
    }
  };

  const fallbackUI = () => {
    return (
      <div className="share-report-mobile-fallback-wrapper">
        <div className="share-report-mobile-fallback-wrapper__error-text">
          <h4>Error</h4>
          <h4>Something Went Wrong...</h4>
        </div>
      </div>
    );
  };

  return (
    <ShareReportErrorBoundary
      fallback={fallbackUI()}
      isLoading={isLoading}
      loaderWrapperClass="pain-overview-wrapper pain-overview-loader"
      loaderImgStyle={{ position: "relative", top: "8px" }}
    >
      <div className="pain-overview-wrapper">
        {
          hasError ?
          (
            <>
              <span className="text-font-small-grey" style={{ fontWeight: "400" }}>Error Logs:</span>
              <span className="text-font-small-grey" style={{ fontWeight: "300" }}>{JSON.stringify(errorLogs)}</span>
            </>
          )
          :
          (hasSubmittedSurvey ? 
            <>        
                <div className="pain-score-title title-font-large">
                  <p className="title-font-regular">Pain Score</p>
                  <button onClick={seeMoreClicked} className="detailed-score-button text-font-small-grey">
                    See More
                    <img style={{ width: "16px", height: "16px" }} src={arrowInsideMobile}/>
                  </button>
                </div>
                <div className="pain-score-value-wrapper">
                  <div className="pain-score-value">
                    <span className="title-font-large">{painScoreDate?.pain_score}</span>
                    <span className="text-font-small-grey" style={{ marginLeft: "4px", fontWeight: "300" }}>
                      Last Reported {dateStringToDateWithMMDDYYYYFormat(painScoreDate?.survey_submitted_at)}
                    </span>
                  </div>
                  <img src={getIndicatorImage()} className="pain-score-emoji" alt="Indicator Face"/>
                </div>
            </> 
          : 
            <>
              <p className="title-font-regular" style={{ marginTop: "16px", marginBottom: "4px" }}>Complete Survey</p>
              <span className="text-font-small-grey" style={{ fontWeight: "300" }}>
                Completing the survey will show the progress chart.
              </span>
            </>
          )
        }
      </div>
    </ShareReportErrorBoundary>
  );
};

export default PainOverview;
