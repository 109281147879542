import React from "react";
import PatsBodyWrapper from "containers/PatsPatientOrders/Components/PatsOrderBodyWrapper";
import EditIcon from '@mui/icons-material/Edit';
import AddressCard from "containers/PatsPatientOrders/Components/AddressCard";

function ConfirmShippingDetails(props) {
  const {
    title,
    toggleEditShippingDetails,
    addressData,
    onSubmitShippingDetails
  } = props;


  const getBody = () => {
    return (
      <AddressCard addressData={addressData} highlightName={true} />
    );
  };

  return (
    <PatsBodyWrapper
      title={<h4 className="title-font">{title}</h4>}
      body={getBody()}
      buttons={[
        <button
          className="pats-btn-primary"
          onClick={() => onSubmitShippingDetails()}
        >
          Yes, my address is correct
        </button>,
        <button
          className="pats-btn-secondary"
          onClick={toggleEditShippingDetails}
        >
          <div className="edit-button-wrapper">
            <EditIcon sx={{ fontSize: "24px" }} />
            Edit Shipping Info
          </div>
        </button>
      ]}
    />
  );
}

export default ConfirmShippingDetails;
